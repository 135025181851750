body {
  background-color: #e7edf5;
  min-width: 360px;
}

body.mini-navbar .nav-header {
  background-color: #4ebc4c;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.border-darkblue {
  border-color: #053985; 
}

.border-orange {
  border-color: #e04403; 
}

.border-green {
  border-color: #4ebc4c;
}

.bg-blue {
  background-color: #c0f1ff;
}

.bg-softgreen {
  background-color: #ccf1cf;
}

.bg-darkblue {
  background-color: #053985;
}

.bg-gray {
  background-color: #f8f8f8;
  /* background: #eeeeee; */
}

.bg-green {
  background-color: #4ebc4c;
}

.bg-orange {
  background-color: #e04403;
}

.btn-gray {
  background-color: #eeeeee;
}

.font-arial {
  font-family: Verdana, Geneva, Tahoma, sans-serif
}

.mainpageImage {
  background-image: url('../img/bg-creditscreen1.png');
  background-size: cover;
  background-position: center;
  height: 100%;
  position: fixed;
  z-index: -1;
  width: 100%;
}

.customerServiceImage {
  background-image: url('../img/customerServiceImage.png');
  background-size: cover;
  background-position: center;
  height: 100%;
  margin-left: -25px;
  margin-top: -118px;
  position: absolute;
  z-index: -1;
  width: 100%;
}

.welcomeImage {
  background-image: url('../img/welcomeImage.png');
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  margin-top: -118px;
  margin-left: -25px;
}

.bg-message-user {
  background-color: #d9fdd3;
}

.bg-widget:hover {
  background-color: #e7edf5;
  cursor: pointer;
}

.br-10 {
  border-radius: 10px;
}

.btn-darkblue {
  background-color: #053985;
  color: #fff;
}

.btn-darkblue:hover {
  background-color: #053985;
  color: #fff;
  opacity: 0.9;
}

.btn-green {
  background-color: #4ebc4c;
  color: #fff;
}

.btn-green:hover {
  background-color: #4ebc4c;
  color: #fff;
  opacity: 0.8;
}

.btn-link:hover, .btn-link:focus {
  color: #4ebc4c;
  text-decoration: none;
}

.btn-orange {
  background-color: #e04403;
  color: #fff;
}

.btn-orange:hover {
  background-color: #e04403;
  color: #fff;
  opacity: 0.7;
}

.text-decoration-underline {
  text-decoration: underline;
}

.btn-white:hover, .btn-white:focus, .btn-white:active, .btn-white.active, .open .dropdown-toggle.btn-white, .btn-white:active:focus, .btn-white:active:hover, .btn-white.active:hover, .btn-white.active:focus {
  background-color: #4ebc4c;
  border-color: #fff;
  color: #fff;
}

.form-input:focus {
  border-color: #e6e6e6;
}

.font-serif {
  font-family: Arial, Helvetica, sans-serif;
}

.min-height {
  min-height: 75vh;
}

.message-history{
  height: 400px;
  overflow-y: scroll;
}

.nav-header {
  background: #4ebc4c;
  padding: 33px 25px;
}

.navbar-border {
  background-color: #4ebc4c;
  border-color: white;
  border-right-style: groove;
}

.navbar-button {
  border-color: #000;
  color: #000;
}

.navbar-button:hover {
  background-color: #4ebc4c;
  border-color: #fff;
  color: #fff;
  opacity: 1;
}

.nav-second-level {
  background-color: #4ebc4c !important;
}

.nav.navbar-right .logout {
  color: #000;
}

.nav.navbar-right .logout:hover {
  color: #000;
  opacity: 0.7;
}

.nav > li.active {
  border-left: 4px solid #e7edf5;
}

.nav > li.active > a {
  background: #4ebc4c;
  opacity: 0.8;
}

.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus {
  background-color: #1b8f18;
  color: white;
  opacity: 0.7;
}

.table-image {
  width: 100px;
}

.text-blue {
  color: #007bff;
}

.text-black {
  color: #000000;
}

.text-black-file {
  color: #000000;
}

.text-darkblue {
  color: #053985;
}

.text-gray {
  color: #e7edf5;
}

.text-green {
  color: #4ebc4c;
}

.text-orange {
  color: #E04403;
}

.text-h1 {
  font-size: 80px;
}

.text-h2 {
  font-size: 40px;
}

.text-h3 {
  font-size: 30px;
}


.indexedStep.accomplished {
  background-color: #E04403;
  border-style: none;
  color: white;
}

.RSPBprogressBar {
  align-items: center;
  background-color: rgb(207, 207, 207);
  border-radius: 10px;
  display: flex;
  height: 4px!important;
  justify-content: space-between;
  line-height: 1;
  margin: 20px auto;
  position: relative;
  width: 50%;
  z-index: 0;
}

.RSPBprogressBar .RSPBstep {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: absolute;
  transform: translateX(-50%);
  transition-property: all;
  transition-timing-function: ease; 
  z-index: 0;
}

.RSPBprogressBar .RSPBprogressBarText {
  color: white;
  left: 50%;
  font-size: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.RSPBprogressBar .RSPBprogression {
  background: #E04403!important;
  border-radius: 10px;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: width 0.3s ease;
  z-index: -1;
}

@media screen and (max-width: 480px) {
  .indexedStep{
    height: 30px;
    font-size: 12px;
    width: 30px;
  }
}

.multi-range-slider{
  border: none!important;
  box-shadow: none!important;
}

.multi-range-slider .bar-right {
  background-color: #ebe9e9!important;
  box-shadow: none!important;
}

.multi-range-slider .bar-left {
  background-color: #ebe9e9!important;
  box-shadow: none!important;
}

.multi-range-slider .bar-inner {
  background-color: #e04403!important;
  box-shadow: none!important;
}

.multi-range-slider .thumb::before {
  border: none!important;
}

.swal2-popup {
  height: 150%;
  width: 700px!important;
}

@media (max-width: 768px) {
  .text-h1 {
    font-size: 50px;
  }

  .text-h2 {
    font-size: 30px;
  }

  .text-h3 {
    font-size: 20px;
  }

  .text-h4 {
    font-size: 18px;
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
}

.item {
  align-items: center;
  color: #FFF;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  background-color: red;
}

.pagination > .activePage > a {
  cursor: pointer;
  z-index: 2;
  background-color: #a1a1a1;
  color: white;
}